import { Grid, Stack } from '@mui/material';
import React from 'react';

type Props = {
  reverse?: boolean;
  image: string;
  children?: React.ReactNode;
};

export default function ImageInfo({ reverse = false, image, children }: Props) {
  return (
    <Grid container spacing={4} flexDirection={reverse === true ? 'row-reverse' : 'row'}>
      <Grid item md={6}>
        <img
          src={image}
          alt="sheesh"
          width="560"
          height="315"
          style={{
            borderRadius: '5px',
            display: 'inline-block',
            width: '100%',
            height: 'auto',
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Stack direction="column">{children}</Stack>
      </Grid>
    </Grid>
  );
}
