import React, { useState } from 'react';
import {
  Avatar, Box, ButtonBase, Container, Grid, Stack, styled, Typography,
} from '@mui/material';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleDialog from './simple-dialog';
import PrivacyPolicy from './policies/privacy-policy';
import { BUSINESS_ADDRESS } from '../constants';

export default function Footer() {
  const StyledText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
  }));

  const StyledAvatar = styled(Avatar)`
    ${({ theme }) => `
  cursor: pointer;
  background-color: ${theme.palette.primary.main};
  transition: ${theme.transitions.create(['background-color', 'transform'], {
    duration: theme.transitions.duration.standard,
  })};
  &:hover {
    background-color: ${theme.palette.primary.light};
    transform: scale(1.3);
  }
  `}
  `;

  const StyledLink = styled(Typography)`
    ${({ theme }) => `
cursor: pointer;
text-decoration: none;
color: ${theme.palette.primary.contrastText};
transition: ${theme.transitions.create(['color'], {
    duration: theme.transitions.duration.standard,
  })};
&:hover {
  text-decoration: underline;
  color: ${theme.palette.secondary.dark};
}
`}
  `;

  const [open, setOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <Box bgcolor="primary.main">
        <Container maxWidth="lg">
          <Grid container columnSpacing={3} padding="1em 0 1em">
            <Grid item xs={12} sm={4}>
              <StyledText variant="h6" fontWeight="fontWeightBold">
                Contacto
              </StyledText>
              <StyledText>&#9742; (593)-967-618-791</StyledText>
              <StyledText variant="h6" fontWeight="fontWeightBold">
                Dirección
              </StyledText>
              <StyledText>{BUSINESS_ADDRESS}</StyledText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledText variant="h6" fontWeight="fontWeightBold">
                Servicios
              </StyledText>
              <StyledLink variant="body2">Desarrollo y Establecimiento de Negocios</StyledLink>
              <StyledLink variant="body2">Finanzas Bancarias</StyledLink>
              <StyledLink variant="body2">
                Desarrollo de proyectos inmobiliarios, comerciales e industriales
              </StyledLink>
              <StyledLink variant="body2">
                Desarrollo de proyectos de Inversión para solicitar acuerdos de Alianza Publico
                Privado
              </StyledLink>
              <StyledLink variant="body2">
                Implementación de métodos de valoración de acuerdo al negocio
              </StyledLink>
              <StyledLink variant="body2">
                Diagnóstico de la empresa en todos sus procesos
              </StyledLink>
            </Grid>
            <Grid item xs={12} sm={2}>
              <StyledText variant="h6" fontWeight="fontWeightBold">
                Redes Sociales
              </StyledText>
              <Stack spacing={2} direction="row">
                <a href="https://www.facebook.com/Cofiad-Consultor%C3%ADa-Financiera-Administrativa-103717532457838/">
                  <StyledAvatar sx={{ bgcolor: '#385898' }}>
                    <FontAwesomeIcon icon={faFacebookF} size="lg" />
                  </StyledAvatar>
                </a>
                <br />
                <a href="https://instagram.com">
                  <StyledAvatar
                    sx={{
                      background:
                        'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
                    }}
                  >
                    <FontAwesomeIcon icon={faInstagram} size="lg" />
                  </StyledAvatar>
                </a>
              </Stack>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg" sx={{ padding: '1em' }}>
          <Box borderBottom={1} marginBottom="1em" color="common.white" />
          <Stack spacing={2} direction="row">
            <StyledText variant="body2">
              &copy;
              {currentYear}
              {' '}
              COFIAD
            </StyledText>
            <ButtonBase disableRipple onClick={() => setOpen(true)}>
              <StyledLink variant="body2">Política de Privacidad</StyledLink>
            </ButtonBase>
            <SimpleDialog open={open} onClose={() => setOpen(false)}>
              <PrivacyPolicy />
            </SimpleDialog>
          </Stack>
        </Container>
      </Box>
    </footer>
  );
}
