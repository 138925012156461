import {
  Box, Button, Divider, Typography,
} from '@mui/material';
import ImageInfo from 'components/image-info';
import Section from 'components/section';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import CaptionedImage from 'components/captioned-image';
import TextBlock from 'components/text-block';
import landingImage from '../assets/fallbackFileFormat/landing-image.jpeg';
import serviceOneImage from '../assets/fallbackFileFormat/service-one.jpg';
import serviceTwoImage from '../assets/fallbackFileFormat/service-two.jpg';
import serviceThreeImage from '../assets/fallbackFileFormat/service-three.jpg';
import serviceFourImage from '../assets/fallbackFileFormat/service-four.jpg';
import serviceLastImage from '../assets/fallbackFileFormat/services-five-and-six.jpg';

import reasonOne from '../assets/fallbackFileFormat/reason-one.png';
import reasonTwo from '../assets/fallbackFileFormat/reason-two.png';
import reasonThree from '../assets/fallbackFileFormat/reason-three.png';

import '../styles/Home.css';

function Home() {
  const navigate = useNavigate();

  return (
    <div>
      {/* Need to extract this into a reusuable chunk */}
      <section>
        <Box position="relative" sx={{ backgroundColor: 'black' }}>
          <img
            src={landingImage}
            alt=""
            width="1470"
            height="980"
            style={{
              display: 'inline-block',
              width: '100%',
              height: 'auto',
              opacity: '.6',
            }}
          />
          <Box sx={{ position: 'absolute', top: '8%', left: '8%' }}>
            <Typography
              color="white"
              sx={{
                opacity: '.7',
                fontSize: '3vw',
              }}
            >
              Aquí para mejorar su negocio
            </Typography>
          </Box>
        </Box>
      </section>
      {/* WHY US SECTION <BEGIN> */}
      <Section>
        <Typography variant="h2" textAlign="center">
          Por qué elegirnos?
        </Typography>
        <CaptionedImage
          imgSrc={reasonOne}
          height={360}
          width={480}
          imgStyle={{
            display: 'inline-block',
            width: '75%',
            height: 'auto',
          }}
        >
          <Box>
            <Typography variant="h5">Recursivos</Typography>
            <Typography textAlign="justify">
              Las empresas necesitan un soporte técnico externo con un cúmulo de personas que
              cuenten con los conocimientos, experticias y herramientas en las diferentes
              estructuras de Mercado sean estas comercial, financiera y productiva.
            </Typography>
          </Box>
        </CaptionedImage>
        <CaptionedImage
          direction="row-reverse"
          imgSrc={reasonTwo}
          height={360}
          width={480}
          imgStyle={{
            display: 'inline-block',
            width: '75%',
            height: 'auto',
          }}
        >
          <Box>
            <Typography variant="h5">Profesionalismo</Typography>
            <Typography textAlign="justify">
              Nuestros profesionales cuentan con dos cualidades importantes como son:
            </Typography>
            <ul>
              <li>Voluntad profesional</li>
              <li>humildad personal</li>
            </ul>
          </Box>
        </CaptionedImage>
        <CaptionedImage
          imgSrc={reasonThree}
          height={360}
          width={480}
          imgStyle={{
            display: 'inline-block',
            width: '100%',
            height: 'auto',
          }}
        >
          <Box>
            <Typography variant="h5">Calidad</Typography>
            <Typography textAlign="justify">
              COFIAD busca que las empresas no solo sean buenas en la actividad productiva sino
              sobresalientes.
            </Typography>
          </Box>
        </CaptionedImage>
        <Box sx={{ marginTop: '2em', display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" color="info" onClick={() => navigate('/contact')}>
            Contáctenos
          </Button>
        </Box>
      </Section>
      {/* SERVICE ONE SECTION <BEGIN> */}
      <Section fadeInRight bgColor="#eeebe8">
        <ImageInfo image={serviceOneImage}>
          <TextBlock title="Constitución y desarrollo de un negocio ">
            La mayoría de los negocios en el país no cuentan con un RUC jurídico que les permita
            crecer en el ámbito productivo, financiero y administrativo en las estructuras del
            mercado.
          </TextBlock>
        </ImageInfo>
      </Section>
      <Section>
        <ImageInfo reverse image={serviceTwoImage}>
          <TextBlock title="Fuentes de financiamiento en la banca pública y/o privada">
            El financiamiento que requieran los negocios dependerá del tiempo de su constitución,
            tamaño y de los estados financieros (Situación Inicial y Estado de Resultados
            Integrales). Por otro lado, el monto dependerá si es para Activos fijos o Capital de
            Trabajo.
          </TextBlock>
        </ImageInfo>
      </Section>
      <Section fadeInRight>
        <ImageInfo image={serviceThreeImage}>
          <TextBlock title="Desarrollo de proyectos inmobiliarios, comerciales e industriales">
            El desarrollo del proyecto cuenta con los respectivos estudios del producto y/o
            servicio, levantamiento de información si no existiera datos secundarios, estados
            financieros proyectados, estudios técnicos del proyecto (cronograma valorado de
            ejecución de obra, planos arquitectónicos - implantación (construcción, eléctrico,
            estructural, hidrosanitario y sistema especiales), presupuesto consolidado de
            construcción y documento de ingreso de planos a la entidad correspondiente) y análisis
            de riesgo.
          </TextBlock>
        </ImageInfo>
      </Section>
      <Section bgColor="#eeebe8">
        <ImageInfo image={serviceFourImage}>
          <TextBlock title="Desarrollo de proyectos de Inversión para solicitar acuerdos de Alianza Público Privado (APP)">
            El desarrollo del proyecto de inversión cuenta con los respectivos estudios del producto
            y/o servicio, levantamiento de información si no existiera datos secundarios, estados
            financieros proyectados. Todo esto, utilizando los formatos y anexos del kit de
            inversiones.
          </TextBlock>
        </ImageInfo>
      </Section>
      <Section fadeInRight bgColor="#eeebe8">
        <ImageInfo reverse image={serviceLastImage}>
          <TextBlock title="Implementación de métodos de valoración de acuerdo al negocio">
            Métodos de valoración por medio del Balance, cuentas de resultado, descuentos de flujos
            y creación de valor.
          </TextBlock>
          <Divider sx={{ marginTop: '1em', marginBottom: '1em' }} />
          <TextBlock
            title="Diagnóstico de la empresa en todos sus procesos"
            subtitle="(Planificación, Producción y
              Distribución)"
          >
            El diagnóstico comprende un estudio pormenorizado en la cadena de valor y en las cinco
            fuerzas de Porter.
          </TextBlock>
        </ImageInfo>
      </Section>
    </div>
  );
}

export default Home;
