import React, { useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';

type Props = {
  isLoading?: boolean;
  timeout?: number;
  type?: 'button' | 'submit' | 'reset' | undefined;
  callback?: () => void;
  children: React.ReactNode;
};

export default function LoadButton({
  isLoading = false,
  timeout = 750,
  type = 'button',
  callback,
  children,
}: Props) {
  const [showLoader, setShowLoader] = useState(false);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (isLoading) {
      setShowLoader(true);
    }

    // Show loader a bits longer to avoid loading flash
    if (!isLoading && showLoader) {
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, timeout);

      // Don’t forget to clear the timeout
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isLoading, showLoader]);
  return (
    <Box sx={{ m: 1, position: 'relative' }}>
      <Button type={type} variant="contained" disabled={showLoader} onClick={callback}>
        {showLoader ? <CircularProgress size={24} /> : children}
      </Button>
    </Box>
  );
}
