import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import themeOptions from 'styles/themes';

import Navbar from 'components/navbar';
import Services from 'pages/servicesPage';
import ContactPage from 'pages/contactPage';
import Footer from 'components/footer';
import axios from 'axios';
import Home from './pages/homePage';

import './styles/index.css';

import reportWebVitals from './reportWebVitals';

const theme = createTheme(themeOptions);

// Axios Settings
axios.defaults.baseURL = 'https://api.cofiad.com.ec';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <div
          style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Navbar />
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="services" element={<Services />} />
              <Route path="contact" element={<ContactPage />} />
              <Route
                path="*"
                element={(
                  <main
                    style={{
                      padding: '1rem',
                    }}
                  >
                    Sheesh we have an invalid route!
                  </main>
                )}
              />
            </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
