import { Dialog } from '@mui/material';
import React from 'react';

export interface SimpleDialogProps {
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

export default function SimpleDialog({ open, onClose, children }: SimpleDialogProps) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog disableScrollLock maxWidth="md" open={open} onClose={handleClose}>
      {children}
    </Dialog>
  );
}
