import React from 'react';
import {
  Grid, GridDirection, styled, SxProps, useMediaQuery, useTheme,
} from '@mui/material';

type Props = {
  direction?: GridDirection | undefined;
  imgSrc: string;
  width?: string | number | undefined;
  height?: string | number | undefined;
  imgStyle?: SxProps | undefined;
  children?: React.ReactNode;
};

// Allow user to define direction, image, text as child prop.
export default function CaptionedImage({
  direction = 'row',
  imgSrc,
  imgStyle,
  width,
  height,
  children,
}: Props) {
  const StyledImage = styled('img')({
    borderRadius: '5px',
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      direction={matches ? 'row' : direction}
      rowGap={2}
      justifyContent="center"
      sx={{ marginTop: '1em' }}
    >
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
        <StyledImage src={imgSrc} alt="?" width={width} height={height} sx={imgStyle} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '1em',
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
}
