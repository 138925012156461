import { ThemeOptions } from '@mui/material';

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#84c6e5',
    },
    secondary: {
      main: '#54BAB9',
    },
    error: {
      main: '#f44336',
    },
    success: {
      main: '#81c784',
    },
    info: {
      main: '#607d8b',
    },

    background: {
      default: '#fafafa',
    },
  },
};
export default themeOptions;
