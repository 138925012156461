import React from 'react';
import { Box, Typography } from '@mui/material';

type Props = {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
};

export default function TextBlock({ title, subtitle, children }: Props) {
  return (
    <Box>
      <Typography variant="h5">{title}</Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
      <Typography variant="body1">{children}</Typography>
    </Box>
  );
}
