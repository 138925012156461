import React, { useEffect, useRef, useState } from 'react';
import { Breakpoint, Container, useTheme } from '@mui/material';
import '../styles/section.css';

type Props = {
  maxWidth?: false | Breakpoint | undefined;
  bgColor?: string;
  fadeInRight?: boolean;
  children?: React.ReactNode;
};

export default function Section({
  maxWidth, bgColor, children, fadeInRight = false,
}: Props) {
  const [isVisible, setVisibility] = useState(true);

  const fadeInClass = fadeInRight ? 'fade-in-section-right' : 'fade-in-section-left';

  const domRef = useRef<HTMLElement>(null);

  const theme = useTheme();

  if (domRef !== null) {
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => setVisibility(entry.isIntersecting));
      });

      const localRef = domRef.current;

      observer.observe(domRef.current!);

      return () => {
        observer.unobserve(localRef!);
      };
    }, []);
  }
  return (
    <section
      className={`${fadeInClass} ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
      style={{
        backgroundColor: bgColor !== undefined ? bgColor : theme.palette.background.default,
        paddingTop: '3em',
        paddingBottom: '3em',
      }}
    >
      <Container maxWidth={maxWidth}>{children}</Container>
    </section>
  );
}
