import { Close, LocalPhoneOutlined } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import axios, { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import LoadButton from './load-button';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  message: string;
};

export default function ContactForm() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({ mode: 'onChange' });

  const [hasError, setHasError] = useState(false);
  const [alertValue, setAlertValue] = useState('');

  const [alertOpen, setAlertOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const onSubmitForm = async (data: FormData) => {
    setAlertOpen(false);

    setLoading(true);

    const payload = JSON.stringify(data);

    try {
      const res = await axios.post('/contact', payload, {
        headers: { 'Content-Type': 'application/json' },
      });

      setHasError(false);
      setAlertValue(res.data.msg);
    } catch (err) {
      setHasError(true);

      if (err instanceof AxiosError) {
        setAlertValue(err.message);
      } else {
        setAlertValue('Oops something went very wrong!');
      }
    }

    setAlertOpen(true);
    setLoading(false);
  };

  const onSubmitError = () => {
    setAlertOpen(true);
    setHasError(true);
    setAlertValue('Fill in all required fields.');
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm, onSubmitError)} noValidate>
      <Collapse in={alertOpen} sx={{ marginBottom: '1em' }}>
        <Alert
          severity={hasError ? 'error' : 'success'}
          variant="filled"
          action={(
            <IconButton aria-label="close" size="small" onClick={() => setAlertOpen(false)}>
              <Close fontSize="inherit" sx={{ color: 'white' }} />
            </IconButton>
          )}
        >
          <AlertTitle>{hasError ? 'Error' : 'Success'}</AlertTitle>
          {alertValue}
        </Alert>
      </Collapse>
      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={6}>
          <TextField
            {...register('firstName', {
              validate: {
                notEmpty: (v) => v !== '' || 'This field is required',
                onlyCharacters: (v) => v.match(/^([^0-9]*)$/) !== null || 'Numbers can not exist in First Name',
              },
            })}
            required
            error={!!errors?.firstName}
            helperText={errors?.firstName ? errors.firstName.message : ' '}
            label="Nombre"
            id="firstName"
            variant="outlined"
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...register('lastName', {
              validate: {
                notEmpty: (v) => v !== '' || 'This field is required',
                onlyCharacters: (v) => v.match(/^([^0-9]*)$/) !== null || 'Numbers can not exist in Last Name',
              },
            })}
            label="Apellido"
            id="lastName"
            variant="outlined"
            required
            error={!!errors?.lastName}
            helperText={errors?.lastName ? errors.lastName.message : ' '}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('email', {
              validate: {
                notEmpty: (v) => v !== '' || 'This field is required',
                onlyCharacters: (v) => v.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/) !== null
                  || 'Invalid email',
              },
            })}
            label="Correo Electrónico"
            id="email"
            variant="outlined"
            required
            error={!!errors?.email}
            helperText={errors?.email ? errors.email.message : ' '}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs>
          <TextField
            {...register('companyName', {
              validate: {
                notEmpty: (v) => v !== '' || 'This field is required',
              },
            })}
            required
            error={!!errors?.companyName}
            helperText={errors?.companyName ? errors.companyName.message : ' '}
            label="Nombre de la Empresa"
            id="companyName"
            variant="outlined"
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalPhoneOutlined />
                </InputAdornment>
              ),
            }}
            {...register('phoneNumber', {
              validate: {
                notEmpty: (v) => v !== '' || 'This field is required',
              },
            })}
            required
            error={!!errors?.phoneNumber}
            helperText={errors?.phoneNumber ? errors.phoneNumber.message : ' '}
            label="Phone Number"
            id="phoneNumber"
            variant="outlined"
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('message')}
            id="messageBody"
            variant="outlined"
            multiline
            placeholder="Information regarding inquiry..."
            minRows={3}
            sx={{ width: '100%' }}
            helperText=" "
          />
        </Grid>
        <Grid item xs={12}>
          <LoadButton type="submit" isLoading={isLoading}>
            Enviar
          </LoadButton>
        </Grid>
      </Grid>
    </form>
  );
}
