import React from 'react';
import {
  AppBar, Box, Container, styled, Tab, Tabs,
} from '@mui/material';
import { Home, Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import '../styles/Navbar.css';
import logo from '../assets/fallbackFileFormat/logo.png';

export default function Navbar() {
  // const isMatch = useMediaQuery('md');

  const StyledTab = styled(Tab)`
    ${({ theme }) => `
color: ${theme.palette.primary.contrastText};
transition: ${theme.transitions.create(['color'], {
    duration: theme.transitions.duration.complex,
  })};
&:hover {
  color: ${theme.palette.secondary.dark};
}
`}
  `;

  const navigate = useNavigate();

  return (
    <AppBar position="sticky" color="primary">
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box flexDirection="row" display="flex" padding="1em">
            <Box
              component="img"
              sx={{
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
              width="128px"
              height="128x"
              src={logo}
              alt="Your logo."
            />
          </Box>
          <Tabs value={false} sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <StyledTab onClick={() => navigate('/')} label="Inicio" disableRipple icon={<Home />} />
            {/* <StyledTab
              onClick={() => navigate('/services')}
              label="Services"
              disableRipple
              icon={<Work />}
            /> */}
            <StyledTab
              onClick={() => navigate('/contact')}
              label="Contacto"
              disableRipple
              icon={<Person />}
            />
          </Tabs>
        </Box>
      </Container>
    </AppBar>
  );
}
